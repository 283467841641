<route>
{
  "meta": {
    "permission": [
      "product.view_productiondetails"
    ]
  }
}
</route>

<template>
  <i-container :opts="opts" :mode="container" @input="$emit('input', $event)">
    <i-table
      :title="
        $tc(product === undefined ? 'details_production' : 'selectDetail', 2)
      "
      :headers="headers"
      app="product.productiondetails"
      api="product.details"
      @click:create="changeRoute({ pk: 'create' })"
      dontEdit
      :otherBtn="product == undefined && detailsBtn.edit"
      :otherBtn2="product != undefined && detailsBtn.add"
    >
      <template v-slot:otherBtn="{ item }">
        <i-btn
          v-if="product == undefined && detailsBtn.edit"
          :title="$tc('edit', 1)"
          outlined
          classes="mx-1"
          icon="mdi-pencil"
          color="info"
          @click="changeRoute(item)"
        />
      </template>
      <template v-slot:otherBtn2="{ item }">
        <i-btn
          v-if="product != undefined && detailsBtn.add"
          :title="$tc('clone', 1)"
          outlined
          classes="mx-1"
          icon="fa-clone"
          color="secondary"
          @click="clone(item.pk)"
        />
      </template>
      <template v-slot:deleteBtn="{ item, remove }">
        <i-btn
          v-if="product == undefined && item.detailNumber"
          :title="$tc('delete', 1)"
          outlined
          classes="mx-1"
          icon="fa-trash"
          color="error"
          @click="remove(item.pk)"
        />
        <span v-else></span>
      </template>
    </i-table>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    product: {
      type: [Number, String]
    },
    template: {
      type: Number
    },
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details: [],
      loading: false
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$tc('price', 2),
          value: 'calc'
        },
        {
          text: this.$tc('product', 2),
          value: 'prod'
        },
        {
          text: this.$tc('productive_process', 1),
          value: 'template_name'
        },
        {
          text:
            this.detailsBtn.delete || this.detailsBtn.edit
              ? this.$t('action')
              : '',
          value: this.detailsBtn.delete || this.detailsBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    detailsBtn() {
      return {
        add:
          this.getPermissions([`product.add_productiondetails`]) ||
          this.isAdmin,
        delete:
          this.getPermissions([`product.delete_productiondetails`]) ||
          this.isAdmin,
        edit:
          this.getPermissions([`product.change_productiondetails`]) ||
          this.isAdmin
      }
    },
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t('clone', {
              value: this.$t('details_production')
            }),
            value: this.value,
            load: this.loading,
            maxWidth: '80%',
            dontSave: true
          }
        default:
          return { fluid: true }
      }
    }
  },
  methods: {
    async clone(pk) {
      let response = await this.$api.product.details.show({
        pk: pk,
        opt: {
          params: {
            clone: true,
            product: this.product
          }
        }
      })
      this.$emit('doneClone', response.data)
      this.$emit('close')
    },
    changeRoute(item) {
      this.$router.push({
        name: 'details-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    }
  }
}
</script>
